import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_van_number_keyboard = _resolveComponent("van-number-keyboard");

  return _openBlock(), _createElementBlock("div", {
    class: "app",
    style: _normalizeStyle({
      '--top-bar-height': $setup.setTopBarHeight
    })
  }, [_createVNode(_component_router_view), _createVNode(_component_van_number_keyboard, {
    "safe-area-inset-bottom": ""
  })], 4);
}