import { nextTick, onMounted, ref } from 'vue';
export default {
  name: 'App',
  components: {},

  setup() {
    const setTopBarHeight = ref("");
    onMounted(() => {
      nextTick(() => {
        var ms = /Html5Plus\/.+\s\(.*(Immersed\/(\d+\.?\d*).*)\)/gi.exec(navigator.userAgent);
        let u = navigator.userAgent;
        let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // console.log(ms)

        console.log(ms && ms.length >= 3);
        console.log(isIos);

        if (ms && ms.length >= 3) {
          // 适配14 pro 和14 pro max
          if (parseFloat(ms[2]) < 50 && isIos) {
            setTopBarHeight.value = "12vw";
          } else {
            setTopBarHeight.value = parseFloat(ms[2]) + "px";
          }
        } else if (isIos) {
          setTopBarHeight.value = "1vw";
        } else {
          setTopBarHeight.value = 88 / 750 * 100 + "vw";
        }
      });
    });
    return {
      setTopBarHeight
    };
  }

};