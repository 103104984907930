import { createApp } from 'vue'
import App from './App.vue'
import Vconsole from "vconsole";
import router from "./router"
// import Vuex from "vuex"
import store from './store/index'
import { createPinia } from 'pinia'
// import { NativeShare } from 'nativeshare'
// let vConsole = new Vconsole()
import {
    Popup,
    Icon,
    Checkbox,
    CheckboxGroup,
    Swipe,
    SwipeItem,
    Switch,
    Dialog,
    Badge,
    Uploader,
    Toast,
    RadioGroup,
    Radio,
    List,
    Field,
    CellGroup,
    DatetimePicker,
    Popover,
    Collapse,
    CollapseItem,
    ImagePreview,
    Tabbar,
    TabbarItem,
} from "vant"
import 'vant/lib/index.css';
import 'vant/es/toast/style';
// import 'vant/es/image-preview/style';
// import 'lib-flexible/flexible.js'
createApp(App)
.use(
    router,
    store,
    Dialog,
    Toast,
    Popup,
    Icon,
    Checkbox,
    CheckboxGroup,
    Swipe,
    SwipeItem,
    Switch,
    Badge,
    Uploader,
    RadioGroup,
    Radio,
    List,
    Field,
    CellGroup,
    DatetimePicker,
    Popover,
    Collapse,
    CollapseItem,
    Tabbar,
    TabbarItem,
    ImagePreview
)
// .use(vConsole)
.use(createPinia())
.mount('#app')
// createApp(App).mount('#app')
